<div class="p-gutter pl-imp overflow-hidden mr-2">
  <div class="mr-2">
    <mat-card class="max-h-16 drop-shadow-2xl  w-full "
     >
      <div class="grid lg:grid-cols-2 xl:grid-cols-2 ">
        <div class="ml-2 col-span-2 flex space-x-4 justify-between">
          <!-- <div class="">
          <button mat-raised-button
            class="hover:cursor-pointer text-black font-semibold h-fit w-fit bg-slate-200 hover:drop-shadow-lg"
            style="border-radius: 5rem;" color="primary" (click)="setBookmarkAll()">
            <mat-icon svgIcon="mat:bookmark" color="primary" class="text-primary h-6 w-6 hover:cursor-pointer">
            </mat-icon>
            &nbsp;
            Bookmark All
          </button>
          &nbsp;
          <button mat-raised-button
            class="hover:cursor-pointer text-black font-semibold h-fit w-fit bg-slate-200 hover:drop-shadow-lg"
            style="border-radius: 5rem;" color="primary" (click)="removeBookmarkAll()">
            <mat-icon svgIcon="mat:delete_outline" color="primary" class="text-primary h-6 w-6 hover:cursor-pointer">
            </mat-icon>
            &nbsp;
            Remove All
          </button>
          </div> -->
          <div
            class="hidden -mt-2 w-[420px] focus:outline-2  sm:flex items-center bg-foreground rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-xl">
            <mat-icon class="text-secondary flex-none" svgIcon="mat:search"></mat-icon>
            <input [(ngModel)]="searchText" #data (keyup)="getInput(data.value)"
              class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-foreground flex-auto"
              placeholder="Search Auctions..." type="text" />
          </div>
          <div *ngIf="!isfilter">
            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalAuction"
              style="height: 20px;margin-top: -16px;" [pageSize]="pageSize" [pageSizeOptions]="[25,50,75]"
              aria-label="Select page">
            </mat-paginator>
          </div>
          <div *ngIf="isfilter">
            <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="totalAuction"
              style="height: 20px;margin-top: -16px;" [pageSize]="pageSize" [pageSizeOptions]="[25,50,75]"
              [pageIndex]="0" aria-label="Select page">
            </mat-paginator>
          </div>
          <div>
            <button  class="ml-4 flex-none" color="primary" mat-icon-button
            matTooltip="Export" type="button" (click)="downloadUploadResult()">
            <mat-icon svgIcon="mat:download"></mat-icon>
  
          </button>
          </div>
        </div>


      </div>
    </mat-card>
  </div>
  <div class="w-full max-h-[55vh] overflow-y-auto pr-2 scroll">
    <div style="height:65vh;" class="bg-white w-full mt-6 flex items-center" *ngIf="textvisible">
      <div class="w-full flex justify-center">
        <div class="w-fit">
          <!-- <img src="../../../../../assets/img/illustrations/loading.gif" alt="" class="w-fit h-fit p-2"> -->
          <span class="font-bold text-xl text-slate-950">Auctions Not Found...</span>
        </div>
      </div>
    </div>
    <div style="height:65vh;" class="bg-white w-full mt-6 flex items-center"
      *ngIf="!(currentMarketWatchList.length > 0) && !textvisible">
      <div class="w-full flex justify-center">
        <div class="w-fit">
          <img src="../../../../../assets/img/illustrations/loading.gif" alt="" class="w-fit h-fit p-2">
          <span class="font-bold text-xl text-slate-950">Fetching Auctions...</span>
        </div>
      </div>
    </div>
    <mat-card class="max-h-full drop-shadow-xl max-w-full w-full hidden-mobile-web mt-4 mb-4"
      *ngFor="let data of currentMarketWatchList;let i = index" [attr.data-index]="i">
      <div class="flex flex-row gap-5">
        <div class="text-sm ">
          <mat-checkbox color="primary" style="position: absolute;
              top: 0;
              left: 0;
              padding: 4px 9px;
              width: fit-content;" [checked]="data.isSelected == 'true' ? true : false" [value]="data"
            (change)="addFavourite($event)" *ngIf="data.isInitiator == 'N' && !data.bookmarkDisable">
          </mat-checkbox>
        </div>
        <div class="cursor-pointer">
          <img class="h-32 w-full object-cover md:w-32  drop-shadow-2xl rounded-2xl" src="{{data.publicImageUrl}}"
            alt="">
        </div>
        <div class="grid w-full gap-y-4">
          <div class="grid lg:grid-cols-5 md:grid-cols-5 sm:md:grid-cols-1 w-full">
            <!-- col-1 -->
            <div class="p-2 text-sm">
              <span class="bg-primary/30 p-2 rounded-md">
                {{data.uniqueNo || data.internalOrdNo}}
              </span>
            </div>
            <!-- col-2 -->
            <div class="text-sm flex mt-2">
              <mat-icon class="text-primary" svgIcon="mat:location_on"></mat-icon>
              <p>{{data.location}}</p>
            </div>
            <!-- col-3 -->
            <div class="flex my-2">
              <div class="flex" *ngIf="data.sessionState=='F'">
                <span>Start Time :</span>&nbsp;<span class="font-bold">{{ data.biddingStartTime | datepipe}}
                </span>
              </div>
              <div class="flex" *ngIf="data.sessionState=='G'">
                <span> Session Start Time :</span>&nbsp;<span class="font-bold">{{ data.biddingStartTime | datepipe}}
                </span>
              </div>
              <div class="flex" *ngIf="data.sessionState !='F' && data.sessionState != 'G'">
                <span>End Time:</span>&nbsp;<span class="font-bold">{{ data.biddingEndTime | datepipe }}</span>
              </div>
            </div>

            <!-- col-4 -->
            <div class="flex col-span-2 justify-end">
              <mat-chip-list class="flex-row" style="display: contents;">
                <mat-chip class="w-28 max-w-[50%]" color="primary" selected *ngIf="data.sessionState == 'T'&&data.sessionState != 'U'">
                  <!-- <mat-icon svgIcon="mat:alarm" style="height: 18px;"></mat-icon> -->
                  <div class="text-right sq" ngMicroInteract [type]="'swing'" [options]="{ duration: 1000}"
                    [trigger]="'mouseover'">
                    <mat-icon svgIcon="mat:alarm" style="height: 20px;"></mat-icon>
                  </div>
                  <strong #timeLeftElement>{{ data.timeLeft |timer| async }}</strong>
                </mat-chip>
                <mat-chip color="accent" selected>
                  <mat-icon svgIcon="mat:shopping_cart" style="height: 20px;"></mat-icon>
                  <span *ngIf="data.ordSide == 'S'">SELL</span>
                  <span *ngIf="data.ordSide == 'B'">BUY</span>
                </mat-chip>
                <mat-chip selected style="background-color: rgb(232, 53, 53);" *ngIf="data.sessionState==null">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Invalid
                </mat-chip>
                <mat-chip selected style="background-color: coral;" *ngIf="data.sessionState=='F'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Scheduled
                </mat-chip>
                <mat-chip selected style="background-color: rgb(93, 204, 237);" *ngIf="data.sessionState=='P'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Provisional
                </mat-chip>
                <mat-chip selected style="background-color: #BD1550;" *ngIf="data.sessionState=='E'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Ended
                </mat-chip>
                <mat-chip color="primary" selected *ngIf="data.sessionState=='T'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Open
                </mat-chip>
                <mat-chip style="background:#187af2;" selected *ngIf="data.sessionState=='U'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Updating
                </mat-chip>
                <mat-chip selected style="background-color: #9616bd;" *ngIf="data.sessionState=='C'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Confirmation
                </mat-chip>
                <mat-chip selected style="background-color: #707172;" *ngIf="data.sessionState=='G'">
                  <mat-icon svgIcon="mat:whatshot" style="height: 20px;"></mat-icon>
                  Gap
                </mat-chip>
              </mat-chip-list>

              <div class="mt-2 ml-4">
                <mat-icon class="text-primary" svgIcon="mat:list" color="primary"
                  (click)="getTimeSessionMap(viewSession,data.tokenId)"></mat-icon>
              </div>
            </div>
            <ng-template #viewSession>
              <div class="flex flex-col">
                <div class="row">
                  <div class="flex flex-row">
                    <h1 class="basis-3/4" style="color:teal;"><strong>Time Session</strong></h1>
                    <button [mat-dialog-close]>
                      <mat-icon class="ml-24" matSuffix svgIcon="mat:close"></mat-icon>
                    </button>
                  </div>
                  <div class="px-2">
                    <table class="text-sm border-2 fixed_header w-full">
                      <thead class="text-black bg-slate-200">
                        <tr style="height: 2rem;">
                          <th class="pr-3 font-medium"><strong> Session Type </strong></th>
                          <th class="pr-3 font-medium"><strong> Start Time </strong></th>
                          <th class="font-medium"><strong> End Time </strong></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="text-center mt-4" style="height:2rem" *ngFor="let session of timeSessionList">
                              <div class="basis-1/5" *ngIf="session.isLoaded=='T'" style="width:auto">
                                <label style="color: green">
                                  <strong> {{session.sessionType}} </strong>
                                </label>
                              </div>
                              <div class="basis-1/5" *ngIf="session.isLoaded !='T'" style="width:auto">
                                <label>
                                  <strong> {{session.sessionType}} </strong>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="mt-4" style="height:2rem" *ngFor="let session of timeSessionList">
                              <div class="basis-2/5" *ngIf="session.isLoaded=='T'" style="width:auto">
                                <label for="start" style="color: green">
                                  {{session.startTime | datepipe}}
                                </label>
                              </div>
                              <div class="basis-2/5" *ngIf="session.isLoaded !='T'" style="width:auto">
                                <label for="start">
                                  {{session.startTime | datepipe}}
                                </label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="mt-4" style="height:2rem" *ngFor="let session of timeSessionList">
                              <div class="basis-2/5" *ngIf="session.isLoaded=='T'" style="width:auto">
                                <label style="color: green">
                                  {{session.endTime | datepipe}}
                                </label>
                              </div>
                              <div class="basis-2/5" *ngIf="session.isLoaded !='T'" style="width:auto">
                                <label>
                                  {{session.endTime | datepipe}}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="grid lg:grid-cols-5 md:grid-cols-5 sm:md:grid-cols-1 w-full h-fit">
            <div class=" text-sm flex">
              <mat-icon class="text-primary" svgIcon="rupees:scale-balance" style="height: 20px;">
              </mat-icon>
              <b>{{data.ordQty}}</b>&nbsp;{{data.qtyUnit}}
            </div>
            <div class="text-sm flex">
              <mat-icon class="text-primary" svgIcon="rupees:currency-inr" style="height: 20px;">
              </mat-icon>
              <b>{{data.ordPrice}}</b>&nbsp;{{data.priceUnit}}
            </div>
            <div class="flex text-sm font-bold">
              <span>Commodity&nbsp;:</span>&nbsp;<p>{{data.commodityCode}} </p>
            </div>
            <div class="flex font-bold">
              <span> Contract&nbsp;:</span>&nbsp;<p>{{data.contractCode}}</p>
            </div>
            <div class="flex">
              <div *ngIf="data.sessionState =='T'">
                <label style="color: rgb(55, 139, 110)"><b> Best bid price:{{data.bestBidPrice || 0}} </b></label>
              </div>
              <div *ngIf="data.sessionState == 'P' || data.sessionState == 'C'">
                <!-- <span>Super H1 price : <b>{{data.superH1Price || 0}}</b></span> -->
                <span><label style="color: rgb(55, 139, 110)"><b>{{ data.ordSide === 'S' ? 'Super H1 price' : (data.ordSide === 'B' ? 'Super L1 price' : '') }}: {{ data.superH1Price != null ? (data.superH1Price | number:'1.2-2') : '0' }}</b>
                </label></span>
              </div>
              <div *ngIf="data.sessionState == 'E'">
                <span>Trade price : <b>{{data.tradePrice || 0}}</b></span>
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="flex flex-row basis-3/4">
              <div class="basis-1/4 ml-2">
                <h6><strong>Extension : </strong> {{data.extensionNo}}</h6>
              </div>
              <div class="basis-1/4 ml-4">
                <mat-icon class="text-primary" svgIcon="mat:store" style="height: 20px;"></mat-icon>
                <span>{{data.paramValue5}}</span>
              </div>
              <div class="2/4 ml-4">
                <strong>
                  {{ data.paramValue9 }}<span *ngIf="data.paramValue9 !== null && data.paramValue9 !== ''"> |</span>
                  {{ data.paramValue10 }}<span *ngIf="data.paramValue10 !== null && data.paramValue10 !== ''"> |</span>
                  {{ data.globalClient }}<span *ngIf="data.globalClient !== null && data.globalClient !== ''"> |</span>
                  {{ data.paramValue8 }}<span *ngIf="data.paramValue8 !== null && data.paramValue8 !== ''"> |</span>
                  {{ data.paramValue7 }}
                </strong>
              </div>
            </div>
            <div class="basis-1/4">
              <div class="flex justify-end">
                <button mat-button class="ml-16 h-8" (click)="hide(data)">View More Details
                  <mat-icon class="text-primary" svgIcon="mat:keyboard_arrow_down" color="primary"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="" *ngIf="data.ishidden">
            <div class="divider div-transparent my-1"></div>
            <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:md:grid-cols-2 w-full flex flex-wrap">
              <div class="">
                <h6><strong>Description : </strong> {{data.remarks}}</h6>
              </div>

              <div class="flex justify-end">
                <div class="w-35 mr-6">
                  <button class="ml-16" color="primary" mat-raised-button type="button"
                    (click)="viewParameters(viewParams, data)">
                    View Parameter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #viewParams>
        <div class="flex flex-col">
          <div class="row">
            <div class="flex flex-row">
              <h1 class="basis-3/4" style="color:teal;">Parameters</h1>
              <button [mat-dialog-close]>
                <mat-icon class="ml-24" matSuffix svgIcon="mat:close"></mat-icon>
              </button>
            </div>
            <div class="flex flex-row" *ngFor="let param of parameterList">
              <span class="basis-1/2">{{param.parameterName}} : </span>
              <span class="basis-1/2">{{param.parameterValue}} </span>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-card>

  </div>
</div>