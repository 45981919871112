import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimerPipe } from './custom-pipes/timer.pipe';
import { SearchFilterPipe } from './custom-pipes/search-filter.pipe';

import { AlphabetsWithSpaceOnlyDirective } from './Directives/alphanumeric-with-space.directive';
import { NumericOnlyDirective } from './Directives/numeric.directive';
import { characterOnlyDirective } from './Directives/characterOnlyDirective.directive';
import { DateFormatPipe } from './custom-pipes/dateformatpipe';
import { DatepipePipe } from '../directive/datepipe.pipe';
import { SharedDataService } from './Directives/SharedData.service';
import { RestrictInputDirective } from './Directives/restrict-input.directive';
import { TwoDigitDecimaNumberDirective } from '../directive/TwoDigitDecimaNumberDirective.directive';
import { UpperCaseDirective } from './Directives/upperCaseChar.directive';
import { maxMarginDirective } from './Directives/maxMargin.directive';
import { NoSlashInputDirective } from './Directives/input-field.directive';



@NgModule({
  declarations: [TimerPipe,SearchFilterPipe,DateFormatPipe,DatepipePipe,AlphabetsWithSpaceOnlyDirective,characterOnlyDirective,NumericOnlyDirective,RestrictInputDirective,TwoDigitDecimaNumberDirective,UpperCaseDirective,maxMarginDirective,NoSlashInputDirective],
  exports:[TimerPipe,SearchFilterPipe,DatepipePipe,DateFormatPipe,AlphabetsWithSpaceOnlyDirective,characterOnlyDirective,NumericOnlyDirective ,RestrictInputDirective,TwoDigitDecimaNumberDirective,UpperCaseDirective,maxMarginDirective,NoSlashInputDirective],
  imports: [
    CommonModule
  ],
  providers: [SharedDataService],
})
export class SharedModule { }
