import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetwithSpaceCommaOnly]'
})
export class AlphabetsWithSpaceOnlyDirective {


  constructor(private el : ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z\s,\.]/g, '');
    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }
  }
}