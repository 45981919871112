import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformatpipe'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    const utcDate = new Date(value);
    const options = {
   timeZone: "Asia/Kolkata"
    };
    // const istDate = utcDate.toLocaleString("en-US", options);
    const istDate = utcDate.toLocaleDateString("en-IN", options);
    return istDate;
  }
}
