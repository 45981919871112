import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import { WebSocketShareService } from 'src/app/services/websocket.share.service';
import { WebSocketAPI } from 'src/app/services/websocket.service';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;

  
  notifications: string[] = [];
  notificationCount:number=0;


  ngOnInit() {
    this.websocketshare.broadcastuser.subscribe(data => {
      if(data){
        if(data.htmlMessage!=null){
        this.notifications.unshift(data.htmlMessage);
        }
        this.notificationCount=this.notifications.length;
      }
     
    })


    this.websocketshare.broadcastUserMessage.subscribe(data =>{
      if(data){
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if(element.htmlMessage!=null){
            this.notifications.push(element.htmlMessage);
          }
         
        }
        this.notificationCount=this.notifications.length;
      }
    })

    this.ws.getNotifications();
  }

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,private websocketshare:WebSocketShareService,private ws:WebSocketAPI) {}



  showPopover() {
    this.notificationCount=0;
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      data: {
        notifications: this.notifications
      },
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
