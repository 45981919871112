import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EmitterService } from 'src/app/services/emitter.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { WebSocketAPI } from 'src/app/services/websocket.service';
import { WebSocketShareService } from 'src/app/services/websocket.share.service';
import * as _ from 'lodash';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfirmDialogService } from 'src/app/pages/alerts/confirm-dialog.service';
import { UtilService } from 'src/app/services/util.service';
import moment from 'moment';
import * as fs from 'file-saver';


@Component({
  selector: 'vex-current-market-watch-data',
  templateUrl: './current-market-watch-data.component.html',
  styleUrls: ['./current-market-watch-data.component.scss'],
  styles:[`.blink {
    animation: blinker 1s infinite;
  }
  @keyframes blinker {
    from{
      opacity: 1.0;	
    }
    50%{
      opacity: 0.5;
      color: red;
    }
    to{
      opacity: 1.0;
    }
  }`]

})
export class CurrentMarketWatchDataComponent implements OnInit,AfterViewInit,OnDestroy  {

  @Input() currentAuction: any;
  currentMarketWatchList: any = [];
  favouriteList: any = {};
  dataObs: any;
  searchText: any = '';
  emitterData: any = {};
  commodityList: any = [];
  globalClientList: any = [];
  locationList: any = [];
  seasonList: any = [];
  stateList: any = [];
  schemeList: any = [];
  requestModel: any = {};
  parameterList: any = {};
  timeSessionList: any = {};
  bean: any = {};
  bookmarkDisable: boolean = false;
  tradingAccList: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;

  pageSize: any = 25;
  pageIndex = 1;
  limit = this.pageSize;
  offset = 0;
  extension: string = "0";
  parameter: string = '';
  userSession: any;
  textvisible: boolean=false;
  paramList: any;
  isfilter:boolean=false;
  constructor(private fb: UntypedFormBuilder, private apiService: AuthService, private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog, private emmiterService: EmitterService, private tokenService: TokenStorageService, private webSocketService: WebSocketAPI,
    private websocketshareService: WebSocketShareService, private dialogService:ConfirmDialogService) { }
indexForBlink:number;
    @ViewChildren('timeLeftElement') timeLeftElement:QueryList<ElementRef>;
    divElementEE:HTMLElement;
    ngAfterViewInit() {
      this.emmiterService.timerBrodcast.subscribe(data => {
        if(data){
          this.timeLeftElement.forEach((elementRef, index) => {
            //console.warn(elementRef,"index :-",index)
            const transformedValue = elementRef.nativeElement.textContent;  
            this.divElementEE= elementRef.nativeElement;
            if(this.timeToMilliseconds(transformedValue)<181000){
              //console.warn("inside 3 min",elementRef,"index :-",index)
              this.indexForBlink=index;
             //  this.divElementEE.className="blink";     
            }
          });
        }  
     })
     
    }
   timeToMilliseconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
  
      const totalMilliseconds = (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
  
      return totalMilliseconds;
  }
  schedulerId:any;
  ngOnInit(): void {
    this.userSession = this.tokenService.getUser();
    this.requestModel.limit = 25;
    this.requestModel.offset = 0;
    this.commodityList=this.tokenService.getFilter('COMM');
    this.locationList=this.tokenService.getFilter('LOC');
    this.globalClientList=this.tokenService.getFilter('GLOBALCLIENT');
    this.seasonList=this.tokenService.getFilter('SEASON');
    this.stateList=this.tokenService.getFilter('STATE');
    this.schemeList=this.tokenService.getFilter('SCHEME');

    this.requestModel.commodityList = this.commodityList;
    this.requestModel.locationList =  this.locationList;
    this.requestModel.globalClientList = this.globalClientList;
    this.requestModel.seasonList = this.seasonList;
    this.requestModel.stateList = this.stateList;
    this.requestModel.schemeList = this.schemeList;

    this.requestModel.tradingAccNo = this.tokenService.getSelectedTac();
    if (this.tokenService.getSelectedTac) {
      this.bookmarkDisable = false;
    }
    this.tokenService.routeBrodcast.subscribe((data)=>{
      if(data=='SM'){
        this.getCurrentMarketWatchData(this.requestModel);
      }
    })
    this.getCurrentMarketWatchData(this.requestModel);

    this.dataObs = this.emmiterService.routeBrodcast.subscribe(data => {
      this.emitterData = data;
      if (this.emitterData && this.emitterData.checked) {
        this.isfilter=false;
        this.requestModel.offset = 0;
        this.isfilter=true;
        if (this.emitterData.filterType == 'COMM') {
          this.commodityList.push(this.emitterData.data);
          this.requestModel.commodityList = this.commodityList;
          this.getCurrentMarketWatchData(this.requestModel);
        } else if (this.emitterData.filterType == 'LOC') {
          this.locationList.push(this.emitterData.data);
          this.requestModel.locationList = this.locationList;
          this.getCurrentMarketWatchData(this.requestModel);
        } else if (this.emitterData.filterType == 'GLOBALCLIENT') {
          this.globalClientList.push(this.emitterData.data);
          this.requestModel.globalClientList = this.globalClientList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'SEASON') {
          this.seasonList.push(this.emitterData.data);
          this.requestModel.seasonList = this.seasonList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'STATE') {
          this.stateList.push(this.emitterData.data);
          this.requestModel.stateList = this.stateList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'SCHEME') {
          this.schemeList.push(this.emitterData.data);
          this.requestModel.schemeList = this.schemeList;
          this.getCurrentMarketWatchData(this.requestModel);
        }
      } else {
        if(this.emitterData && this.emitterData.checked==false){
          this.isfilter=false;
          this.requestModel.offset = 0;
        }
        if (this.emitterData.filterType == 'COMM') {
          const index: number = this.commodityList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.commodityList.splice(index, 1);
          }
          this.requestModel.commodityList = this.commodityList;
          this.getCurrentMarketWatchData(this.requestModel);
        } else if (this.emitterData.filterType == 'LOC') {
          const index: number = this.locationList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.locationList.splice(index, 1);
          }
          this.requestModel.locationList = this.locationList;
          this.getCurrentMarketWatchData(this.requestModel);
        } else if (this.emitterData.filterType == 'GLOBALCLIENT') {
          const index: number = this.globalClientList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.globalClientList.splice(index, 1);
          }
          this.requestModel.globalClientList = this.globalClientList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'SEASON') {
          const index: number = this.seasonList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.seasonList.splice(index, 1);
          }
          this.requestModel.seasonList = this.seasonList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'STATE') {
          const index: number = this.stateList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.stateList.splice(index, 1);
          }
          this.requestModel.stateList = this.stateList;
          this.getCurrentMarketWatchData(this.requestModel);
        }else if (this.emitterData.filterType == 'SCHEME') {
          const index: number = this.schemeList.indexOf(this.emitterData.data);
          if (index !== -1) {
            this.schemeList.splice(index, 1);
          }
          this.requestModel.schemeList = this.schemeList;
          this.getCurrentMarketWatchData(this.requestModel);
        }
      }
    });

    this.tokenService.routeBrodcast.subscribe(data => {
      if (data == 204) {
        this.currentMarketWatchList = [];
      }
    })

    this.dataObs = this.emmiterService.marketWatchBrodcast.subscribe(data => {
      this.emitterData = data;
      if (this.emitterData.data != null && this.emitterData.tc == 900) {
        //this.getCurrentMarketWatchData(this.requestModel);
      } else {
        this.currentMarketWatchList.forEach(x => {
          if (this.emitterData.data.tokenId == x.tokenId) {
            x.sessionState = this.emitterData.data.sessionState;
            x.timeLeft = this.emitterData.data.timeLeft;
            x.extensionNo = this.emitterData.data.extensionNo;
            x.biddingEndTime = this.emitterData.data.sessionEndTime;
          }
        })
        
        //this.currentMarketWatchList = this.sortList(this.currentMarketWatchList);
      }
    });

    this.websocketshareService.broadcastSuperH1.subscribe(data => {
      if (data != null || data.tc == 103) {
        //this.getCurrentMarketWatchData(this.requestModel);
        this.currentMarketWatchList.forEach(x => {
          if (x.tokenId == data.tokenId) {
            x.superH1Price = data.superH1Price;
          }
        })
      }
    })


    this.dataObs = this.emmiterService.auctionFloorSource.subscribe(data => {
      this.emitterData = data;
      if (this.emitterData.data != null) {
        if (this.emitterData.data.mktDataSnapshotModel != null) {
          this.currentMarketWatchList.forEach(x => {
            if (x.tokenId == this.emitterData.data.mktDataSnapshotModel.tokenId) {
              x.bestBidPrice = this.emitterData.data.mktDataSnapshotModel.bestBidPrice;
              x.tradePrice = this.emitterData.data.mktDataSnapshotModel.wtAvgPrice;
            }
          })
        }
      }
    });

    this.dataObs = this.emmiterService.selectedTacBrodcast.subscribe(data => {
      this.emitterData = data;
      if (this.emitterData != null && this.emitterData != "") {
          this.requestModel.tradingAccNo = this.emitterData;
          this.getCurrentMarketWatchData(this.requestModel);
        }
    });

    this.schedulerId = setInterval(() => {
      this.commodityList = this.tokenService.getFilter('COMM');
      this.locationList = this.tokenService.getFilter('LOC');
      this.globalClientList = this.tokenService.getFilter('GLOBALCLIENT');
      this.seasonList = this.tokenService.getFilter('SEASON');
      this.stateList = this.tokenService.getFilter('STATE');
      this.schemeList = this.tokenService.getFilter('SCHEME');

      this.requestModel.commodityList = this.commodityList;
      this.requestModel.locationList = this.locationList;
      this.requestModel.globalClientList = this.globalClientList;
      this.requestModel.seasonList = this.seasonList;
      this.requestModel.stateList = this.stateList;
      this.requestModel.schemeList = this.schemeList;
      this.getCurrentMarketWatchData(this.requestModel);

    }, 30000);
      
  }


  sortList(list) {
    list = _.each([].concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "T" ||o.sessionState=="U") return o; }), ['timeLeft', 'asc']))
      .concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "P") return o; }), ['timeLeft', 'asc']))
      .concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "C") return o; }), ['timeLeft', 'asc']))
      .concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "G") return o; }), ['timeLeft', 'asc']))
      .concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "F") return o; }), ['timeLeft', 'asc']))
      .concat(_.orderBy(_.filter(list, function (o) { if (o.sessionState == "E") return o; }), function (o) { return new Date(o.biddingEndTime); }, ['desc']))
    );
    return list;
  }

  totalAuction: any = 0;

  auctionList: any = [];
  setBookmarkAll() {
    let data:any={};
    data.tradingAccNo=this.tokenService.getSelectedTac();
    if (data.tradingAccNo == undefined || data.tradingAccNo == null) {
      this.dialogService.showError("Please Select Trading Account No");
      return;
    }
    this.apiService.auctionMarketPost('auction-service/marketWatchEM/addAllBookMarks',data).subscribe(
      (data: any) => {
        if (data) {
          this.getCurrentMarketWatchData({});
        }
      }
    )
  }
  removeBookmarkAll() {
    let data:any={};
    data.tradingAccNo=this.tokenService.getSelectedTac();
    if (data.tradingAccNo == undefined || data.tradingAccNo == null) {
      this.dialogService.showError("Please Select Trading Account No");
      return;
    }
    this.apiService.auctionMarketGet('auction-service/marketWatchEM/removeAllBookMarks?tacId=' + data.tradingAccNo).subscribe(
      (data: any) => {
        if (data) {
          this.getCurrentMarketWatchData({});
        }
      }
    )
  }
  getCurrentMarketWatchData(requestModel: any) {
    if (this.currentAuction == 0) {
      this.apiService.auctionMarketPost('auction-service/marketWatchEM/getCurrentMarketWatchGrid', this.requestModel).subscribe(res => {
        if (res) {
          this.totalAuction = res.currentWatchData.totalAuction;
          this.currentMarketWatchList = res.currentWatchData.currentAuction
          this.textvisible=false;
          if(this.currentMarketWatchList.length==0)
          {
            this.textvisible=true;
          }
          this.currentMarketWatchList.forEach(x => {
            if (x.mktDataSnapshotModel) {
              if (x.mktDataSnapshotModel.bestBidPrice != null) {
                x.bestBidPrice = x.mktDataSnapshotModel.bestBidPrice;
              } else if (x.mktDataSnapshotModel.wtAvgPrice != null) {
                x.tradePrice = x.mktDataSnapshotModel.wtAvgPrice;
              }
            }
            
            if ((x.accessAllow == null || x.accessAllow != true || x.accessAllow == '') || this.userSession.orgCategory == 'HST') {
              x.bookmarkDisable = true;
            }else if(this.tokenService.getSelectedTac() == null){
              x.bookmarkDisable = true;
            }
            else {
              x.bookmarkDisable = false;
            }
          });
        } else {
          this.currentMarketWatchList = [];
          this.textvisible=true;
        }
      })
    }
  }



  handlePageEvent(event) {
    console.log(event)
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.requestModel.limit = this.pageSize;
    this.limit=this.requestModel.limit;
    this.requestModel.offset = this.limit * this.pageIndex;
    if(this.emitterData.checked==true && this.pageIndex==0)
    {
      this.requestModel.offset=0;
    }
    this.requestModel.tradingAccNo = this.tokenService.getSelectedTac();
    this.getCurrentMarketWatchData(this.requestModel)
  }

  ishidden: boolean = true;
  hide(data: any) {
    data.ishidden = !data.ishidden;
  }
  ngOnDestroy() {

    if(this.schedulerId){
      clearInterval(this.schedulerId);
    }
    if (this.dataObs) {
      this.dataObs.unsubscribe();
      console.log("this.emitterData", this.emitterData);
      if(typeof this.emitterData === 'object' && this.emitterData.data!=null){
        this.emitterData.filterType = '';
        this.emitterData.data = null;
        this.emitterData.checked = null;
      }
    }
  }

  addFavourite(data: any) {
    this.favouriteList.isSelected = data.checked;
    this.favouriteList.internalOrdNo = data.source.value.internalOrdNo;
    this.favouriteList.contractCode = data.source.value.contractCode;
    this.favouriteList.tradingAccNo = this.tokenService.getSelectedTac();
    this.apiService.auctionMarketPost('auction-service/marketWatchEM/addRemoveBookmark', this.favouriteList).subscribe(res => {
      if (res.getBookMarksResponse.errorCode == 300000) {
        this.requestModel.limit = 5;
        this.requestModel.offset = 0;
        this.currentMarketWatchList.filter(x => x.internalOrdNo == this.favouriteList.internalOrdNo)[0].isSelected = data.checked;
      }
    })

  }

  viewParameters(viewParams, data: any) {
    this.parameterList=[];
    let bean: any = {};
    bean.contractCode = data.contractCode;
    bean.internalOrdNo = data.internalOrdNo;
    bean.tokenId = data.tokenId;
    //this.apiService.auctionMarketPost('auction-service/master/getContractParametersAndValues', bean).subscribe(res => {
      //this.parameterList = res.ContractParametersAndValues;
      this.apiService.auctionMarketGet('auction-service/master/getAuctionDetails?tokenId='+bean.tokenId).subscribe(res => {
      this.paramList = res.getAuctionDetails[0];
      //Mandatory Fields
      if(this.paramList.matchQty != null && this.paramList.matchQty != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Match Qty';
        paramObj['parameterValue'] = this.paramList.matchQty;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.minBidQty != null && this.paramList.minBidQty != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Min Bid Qty';
        paramObj['parameterValue'] = this.paramList.minBidQty;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.auctionType != null && this.paramList.auctionType != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Auction Type';
        paramObj['parameterValue'] = UtilService.decodeauctionType(this.paramList.auctionType);
        this.parameterList.push(paramObj);
      }
      // if(this.paramList.reservePrice != null && this.paramList.reservePrice != "" ){
      //   let paramObj ={};
      //   paramObj['parameterName'] = 'Reserve Price';
      //   paramObj['parameterValue'] = this.paramList.reservePrice;
      //   this.parameterList.push(paramObj);
      // }
      if(this.paramList.tickSize != null && this.paramList.tickSize != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Tick Size';
        paramObj['parameterValue'] = this.paramList.tickSize;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.noOfTicks != null && this.paramList.noOfTicks != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'No. Of Ticks';
        paramObj['parameterValue'] = this.paramList.noOfTicks;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.pricingDate != null && this.paramList.pricingDate != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Pricing Date';
        paramObj['parameterValue'] = moment(this.paramList.pricingDate).format('DD/MM/yyyy');
        this.parameterList.push(paramObj);
      }
      if(this.paramList.deliveryMode != null && this.paramList.deliveryMode != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Delivery Mode';
        paramObj['parameterValue'] = this.paramList.deliveryMode;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.location != null && this.paramList.location != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Location';
        paramObj['parameterValue'] = this.paramList.location;
        this.parameterList.push(paramObj);
      }
      if(this.paramList.paymentMode != null && this.paramList.paymentMode != "" ){
        let paramObj ={};
        paramObj['parameterName'] = 'Payment Mode';
        paramObj['parameterValue'] = this.paramList.paymentMode;
        this.parameterList.push(paramObj);
      }
      console.log(this.parameterList);
      
       for(let i=5;i<=13;i++){
        if(this.paramList['paramValue' + i] != null && this.paramList['paramValue' + i] != "" ){
        let paramObj ={};
        paramObj['parameterName'] = this.paramList['param' + i];
        paramObj['parameterValue'] = this.paramList['paramValue' + i];
        this.parameterList.push(paramObj);
        }
       }

    })
    let dialogRef = this.dialog.open(viewParams, {
      width: '500px'
    });
  }

  getTimeSessionMap(viewSession, tokenId: any) {
    this.apiService.auctionMarketPost('auction-service/master/getTimeSessionDetails', tokenId).subscribe(res => {
      console.log("timeSessionMap", res);
      this.timeSessionList = res.timeSessionDetails;
    })
    let dialogRef = this.dialog.open(viewSession, {
      width: '600px'
    });
  }

  wsData: any;
  searchFilter(event: any) {
    console.log(event)
  }

  @Output() openConfig = new EventEmitter();
  downloadUploadResult() {
    this.apiService.uploadPostData('admin-service/getMarketWatchExcel',this.requestModel).subscribe(data => {
      if (data) {
       var file = new Blob([data]);
       fs.saveAs(file, "CURRENT_WATCH"+new Date().getTime()+".xlsx");
      }
    })
  }


  getInput(event:any){
    console.log(this.requestModel.searchValue);
    // if(Object.entries(event).length>=1||Object.entries(event).length==0){
     // this.requestModel.searchValue=event;
      
        this.offset=0;
        //this.requestModel.searchString=event.toUpperCase();
        this.requestModel.searchString=event;
        this.requestModel.offset=0;
        this.getCurrentMarketWatchData(this.requestModel);
      
    // }
  }

  

}
