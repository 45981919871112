<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="https://neml.in"
       id="get-vex"
       mat-flat-button target="_blank">
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:shopping_bag"></mat-icon>
      <span>NCDEX e-Market Ltd</span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
     Next Generation Auction Engine Platform
    </div>
  </div>
</div>
