<div class="-m-6">
  <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
    <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
    <input type="search"  placeholder="Search..."
      class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary" [formControl]="searchCtrl" [(ngModel)]="searchData"  />
    <!-- <button class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary" type="button" mat-icon-button>
        <mat-icon svgIcon="mat:settings"></mat-icon>
      </button> -->
  </div>
<div class="h-[380px] overflow-y-auto">
  <div class="p-4">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Clients</div>

    <div class="space-y-1">
      <div *ngFor="let item of filterGlobalClientList|searchFilter:searchData"
        class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
        matRipple>
        <!-- <img src="assets/img/commodity/NAFED.jpg" class="w-8 h-8 rounded-full flex-none"/> -->
        <div class="flex-auto text-base font-medium">{{item}}</div>
        <div class="flex-none text-xs text-secondary font-medium flex items-center gap-2">
          <!-- <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon> -->
          <mat-checkbox color="primary" (change)="addFavourite('globalClient',item,$event)"></mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="p-4">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Commodity</div>

    <div class="space-y-1" *ngFor="let item of filterCommodityMasterModelList|searchFilter:searchData">
      <div
        class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
        matRipple>
        <!-- <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
            <img src="assets/img/commodity/CHANA.jpg" class="w-8 h-8 rounded-full flex-none"/>
          </div> -->
        <div class="flex-auto text-base font-medium">
          <div>{{item}}</div>
          <!-- <div class="text-secondary text-xs">50 Auctions Are available Today</div> -->
        </div>
        <!-- <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon> -->

        <mat-checkbox color="primary" (change)="addFavourite('commodity',item,$event)">
        </mat-checkbox>

      </div>
    </div>
  </div>

  </div>