
<div *ngIf="data.dialogType=='confirm'">
  <div *ngIf="data.type=='success'" >
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/success-tick.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>CANCEL</button>
      <button mat-button mat-dialog-close cdkFocusInitial>{{ data.confirmText }}</button>
    </div>
  </div>
  
  <div *ngIf="data.type=='error'">
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/failed-red.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>CANCEL</button>
      <button mat-button mat-dialog-close cdkFocusInitial>{{ data.confirmText }}</button>
    </div>
  </div>
  
  <div *ngIf="data.type=='info'">
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/information.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>CANCEL</button>
      <button mat-button mat-dialog-close cdkFocusInitial>{{ data.confirmText }}</button>
    </div>
  </div>
</div>



<div *ngIf="data.dialogType=='alert'">
  <div *ngIf="data.type=='success'">
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/success-tick.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
  
  <div *ngIf="data.type=='error'">
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/failed-red.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
  
  <div *ngIf="data.type=='info'">
    <h1 mat-dialog-title> <img src="../../../assets/img/icons/gif/information.gif" style="height: 50px;margin-left: 25%;"/></h1>
    <div mat-dialog-content>
        {{ data.message }}
    </div>
    <div mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </div>
  </div>
</div>
