import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';

export interface MegaMenuFeature {
  icon: string;
  label: string;
  route: string;
}

export interface MegaMenuPage {
  label: string;
  route: string;
}

@Component({
  selector: 'vex-mega-menu',
  templateUrl: './mega-menu.component.html'
})
export class MegaMenuComponent implements OnInit {

  features: MegaMenuFeature[] = [
    {
      label: 'Analytics',
      route: '/main/dashboards/analytics',
      icon: 'mat:insights',
    },
    {
      label: 'Market Watch',
      route: '/main/apps/aio-table',
      icon: 'mat:monitor_heart'
    },
    {
      label: 'Auction Floor',
      route: '/main/apps/aio-table',
      icon: 'mat:gavel'
    },
    {
      label: 'Order Book',
      route: '/main/apps/help-center/getting-started',
      icon: 'mat:shopping_cart',
    },
    {
      label: 'Trade Book',
      route: '/main/apps/help-center/pricing',
      icon: 'mat:touch_app',
    },
    {
      label: 'Margin Summary',
      route: '/main/apps/help-center/faq',
      icon: 'mat:attach_money',
    },
    {
      label: 'Auction Tracker',
      route: '/main/apps/help-center/guides',
      icon: 'mat:track_changes',
    }
  ];



  constructor(private popoverRef: PopoverRef<MegaMenuComponent>) { }

  ngOnInit() {
  }

  close() {
    this.popoverRef.close();
  }
}
