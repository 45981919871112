import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vex-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
