import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datepipe'
})
export class DatepipePipe implements PipeTransform {
  transform(value: string): string {
    const utcDate = new Date(value);
    const options = { timeZone: "Asia/Kolkata" };
  // const istDate = utcDate.toLocaleString("en-US", options);
  const istDate = utcDate.toLocaleTimeString("en-US", options);
  return istDate;
  }
}
