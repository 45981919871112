<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>




  <!-- <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Quick Access</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>

    
  </div> -->






  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1">Server Time : {{websocketApi.getServerTime() |dateformatpipe}}&nbsp;{{websocketApi.getServerTime() |datepipe}}</span>

  <div class="-mx-1 flex items-center">

    <div class="basis-1/2">
      <button  mat-icon-button *ngIf="userSession.orgCategory == 'ORG'">
      <mat-icon class="text-primary" svgIcon="mat:autorenew" color="primary" (click)="onRefreshMargin()" ></mat-icon>
    </button>
    </div>
    <div class="basis-1/2">
      <div class="flex flex-col mr-8" *ngIf="userSession.orgCategory == 'ORG'">
        <div class="px-1" style="color: green">
          Available Margin: <strong>{{availableMargin}}</strong>
        </div>
        <div class="px-1" style="color: brown">
          Blocked Margin: <strong>{{blockedMargin}}</strong>
        </div>
      </div>
    </div>


    <div class="flex justify-end mb-[-18px] mt-1.5" *ngIf="userSession.orgCategory == 'ORG'">
      <mat-form-field appearance="outline" class="vex-flex-form-field w-60" color="primary">
        <mat-label>Tac Id</mat-label>
        <mat-select [formControl]="tacControl" (selectionChange)="onChangeTac($event)">
          <mat-option *ngFor="let option of tradingAccList" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>



    <!-- <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon svgIcon="flag:india-flag-icon"></mat-icon>
      </button>
    </div> -->

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>

    <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
      <!-- <button mat-menu-item>
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button> -->

      <!-- <button mat-menu-item>
        <mat-icon svgIcon="flag:india-flag-icon"></mat-icon>
        <span>हिंदी</span>
      </button> -->
    </mat-menu>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"></vex-navigation>