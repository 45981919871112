import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { WebSocketAPI } from '../services/websocket.service';
import { EmitterService } from '../services/emitter.service';
import { NavigationService } from 'src/@vex/services/navigation.service';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthService } from '../services/auth.service';


@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  user:any;

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,private webSocketAPI:WebSocketAPI,private emitter:EmitterService,
              private navigationService: NavigationService,private tokenService:TokenStorageService,
              private api: AuthService) { }

  ngOnInit() {
    this.user = this.tokenService.getUser();
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());

    console.log("User Logged In...");
    this.connect(this.user);

    this.getMenuList();
  }


  connect(user: any) {
    this.webSocketAPI._connectPublic(user);
    this.webSocketAPI._connectUser(user);
  }

  disconnect() {
    this.webSocketAPI._disconnect();
  }

  getMenuList() {

    this.navigationService.items=[];

    this.navigationService.items = [
      {
        type: 'subheading',
        label: 'Dashboards',
        children: [
          {
            type: 'link',
            label: 'Best Bid Price',
            route: '/main/apps/best-bid-price',
            icon: 'mat:insights',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Market Watch',
            route: '/main/apps/market-watch',
            icon: 'mat:monitor_heart',
            routerLinkActiveOptions: { exact: true }
          },
          
        ]
      },
    ]
   
    if(this.user.orgCategory == 'ORG'){
      const dashboardsItem = this.navigationService.items.find(
        (item) => item.type === 'subheading' && item.label === 'Dashboards'
      );
     
      if(dashboardsItem.label == 'Dashboards'){
        this.navigationService.items.push({
          type: 'link',
          label: 'Auction Floor',
          route: '/main/apps/auction-floor',
          icon: 'mat:gavel',
          routerLinkActiveOptions: { exact: true }
        });
      }
    }

    this.api.get('auth-service/menuMaster/getMenuList').subscribe(
      response => {
        console.error("Menu Data",response);
        this.navigationService.items.push(
          {
            type: 'subheading',
            label: 'Features',
            children:response
          }
        )

        //this.navigationService.items.push.apply(this.navigationService.items, response)
        //this.navigationService.items.push(response);
        // this.navigationService.items.push(
        //   {
        //     type: 'subheading',
        //     label: 'Quick Help',
        //     children: [
    
        //       {
        //         type: 'link',
        //         label: 'FAQ',
        //         icon: 'mat:help',
        //         route: '/main/pages/faq'
        //       },
        //       {
        //         type: 'link',
        //         label: 'Guides',
        //         icon: 'mat:book',
        //         route: '/main/pages/guides',
        //         badge: {
        //           value: '18',
        //           bgClass: 'bg-teal',
        //           textClass: 'text-teal-contrast',
        //         },
        //       },
        //     ]
        //   },
        //   {
        //     type: 'link',
        //     label: 'Configuration',
        //     route: () => this.layoutService.openConfigpanel(),
        //     icon: 'mat:settings'
        //   }
        // )

        this.tokenService.saveMenuList(this.navigationService.items);
        
      }
    );
  }


  ngOnDestroy() {
    this.disconnect();
  }
}
