import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[registrationRestrictInput]'
})
export class RestrictInputDirective {

  @Input() validationType: string = 'alphanumeric';

  constructor(private el: ElementRef<HTMLInputElement>) { }

  @HostListener('input', ['$event.target.value']) onInputChange(value: string) {
    if (this.validationType === 'alphanumeric') {
      this.alphanumericValidation(value);
    } else if (this.validationType === 'alpha') {
      this.alphaValidation(value);
    } else if (this.validationType === 'numeric') {
      this.numericValidation(value);
    } else if (this.validationType === 'alphaWithSpace') {
      this.alphaWithSpace(value);
    } else if (this.validationType === 'alphaNumericWithSpace') {
      this.alphaNumericWithSpace(value);
    } else if (this.validationType === 'twoDecimal') {
      this.twoDecimalValidation(value);
    } else if(this.validationType === 'threeDecimal'){
      this.threeDecimalValidation(value);
    } else if(this.validationType === 'mobileNumber'){
      this.mobileNumberValidation(value);
    } else if(this.validationType === 'numericWithDot'){
      this.numericWithDotValidation(value);
    }else if(this.validationType === 'ifscCodeValidation'){
      this.ifscCodeValidation(value);
    }
  }

  //restrict to alphanumeric characters
  private alphanumericValidation(value: string) { 
    const newValue = value.replace(/[^A-Za-z0-9]/g, '');
    this.el.nativeElement.value = newValue;

    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //restrict to alphabetsValidation letters
  private alphaValidation(value: string) {
    const newValue = value.replace(/[^A-Za-z]/g, '');
    this.el.nativeElement.value = newValue;

    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //restrict to numeric
  private numericValidation(value: string) {
    const newValue = value.replace(/[^0-9]/g, '');
    this.el.nativeElement.value = newValue;

    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //allow only first alpha separated by a space
  private alphaWithSpace(value: string) {
    const newValue = value.replace(/[^A-Za-z ]/g, '');
    this.el.nativeElement.value = newValue;

    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //allow only alpanumeric with separated by a space
  private alphaNumericWithSpace(value: string) {
    const newValue = value.replace(/[^A-Za-z0-9 ]/g, '');
    this.el.nativeElement.value = newValue;

    // Update the form control value to match the modified input
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //allow only numeric values with up to two decimal places
  private twoDecimalValidation(value: string) {
    const newValue = value.replace(/[^0-9.]/g, ''); // Allow numeric and decimal point
    const parts = newValue.split('.');
    
    if (parts.length > 2) {
      // More than one decimal point; keep only the first one and remove the rest
      const integerPart = parts.shift();
      const decimalPart = parts.join('');
      this.el.nativeElement.value = integerPart + '.' + decimalPart;
    } else if (parts.length === 2) {
      // At most one decimal point; keep up to two decimal places
      const integerPart = parts[0];
      const decimalPart = parts[1].slice(0, 2);
      const updatedValue=  integerPart + '.' + decimalPart;
      this.el.nativeElement.value =updatedValue;
    } else {
      // No decimal point or just one with no decimal places
      this.el.nativeElement.value = newValue;
    }

    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //allow only numeric values with up to three decimal places
  private threeDecimalValidation(value: string) {
    const newValue = value.replace(/[^0-9.]/g, ''); // Allow numeric and decimal point
    const parts = newValue.split('.');
    if (parts.length > 2) {
      // More than one decimal point; keep only the first one and remove the rest
      const integerPart = parts.shift();
      const decimalPart = parts.join('');
      this.el.nativeElement.value = integerPart + '.' + decimalPart;
    } else if (parts.length === 2) {
      // At most one decimal point; keep up to three decimal places
      const integerPart = parts[0];
      const decimalPart = parts[1].slice(0, 3);
      this.el.nativeElement.value = integerPart + '.' + decimalPart;
    } else {
      // No decimal point or just one with no decimal places
      this.el.nativeElement.value = newValue;
    }
    const inputEvent = new Event('input',{bubbles:true});
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //mobile numbers allow only numeric digits with a specific length (e.g., 10) and starting with 6, 7, 8, or 9
  private mobileNumberValidation(value: string) {
    const newValue = value.replace(/[^0-9]/g, '');
    const maxLength = 10; // Change this value according to your requirements
    const validPrefixRegex = /^(6|7|8|9)/; // Valid prefixes: 6, 7, 8, 9
  
    if (newValue.length > maxLength) {
      this.el.nativeElement.value = newValue.slice(0, maxLength);
    } else if (validPrefixRegex.test(newValue)) {
      this.el.nativeElement.value = newValue;
    } else {
      // If the input doesn't start with a valid prefix, clear the input
      this.el.nativeElement.value = '';
    }
    // Update the form control value to match the modified input
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  //allow only numeric values with decimal places
  private numericWithDotValidation(value: string) {
    const newValue = value.replace(/[^0-9.]/g, '');
    const parts = newValue.split('.');
    if (parts.length > 2) {
      // More than one decimal point; keep only the first one and remove the rest
      const integerPart = parts.shift();
      const decimalPart = parts.join('');
      this.el.nativeElement.value = integerPart + '.' + decimalPart;
    } else {
      // No decimal point or just one with no decimal places
      this.el.nativeElement.value = newValue;
    }
    // Update the form control value to match the modified input
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
  }

  private ifscCodeValidation(value: string) {

    const inputValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');

    if (inputValue.length > 11) {
      this.el.nativeElement.value = inputValue.slice(0, 11);
    } else {
      this.el.nativeElement.value = inputValue;
    }

    // Update the form control value to match the modified input
    const inputEvent = new Event('input', { bubbles: true });
    this.el.nativeElement.dispatchEvent(inputEvent);
  }
}
