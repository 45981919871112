
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // GATEWAY_API: 'http://localhost:8001/',
  // MASTER_API: 'http://localhost:8003/',
  // AUCTION_API: 'http://localhost:8001/',
  // REG_API: 'http://localhost:8004/',
  // AUCTION_MARKET_API: 'http://localhost:8002/',
  // VERIFICATION_API: 'http://172.22.22.181:8010/',
  // BILATERAL_API: 'http://localhost:8007/',
  // UPLOAD_API: 'http://localhost:8021/',
  // publicWebSocketEndPoint : 'https://publicservice-v2-uat.neml.xyz/public-service/broadcast',
  //   userWebSocketEndPoint : 'https://userservice-v2-uat.neml.xyz/user-service/notifications',
  // FUNDS_API: 'http://localhost:9099',
  // BANK_API: 'http://localhost:8081/'

  GATEWAY_API: window["env"]["gateway"] || "default",
  MASTER_API: window["env"]["master"] || "default",
  AUCTION_API: window["env"]["auction"] || "default",
  REG_API: window["env"]["reg"] || "default",
  AUCTION_MARKET_API: window["env"]["auctionmarket"] || "default",
  VERIFICATION_API: window["env"]["verification"] || "default",
  BILATERAL_API: window["env"]["bi"] || "default",
  UPLOAD_API: window["env"]["upload"] || "default",
  publicWebSocketEndPoint: window["env"]["publicWebSocketEndPoint"] || "default",
  userWebSocketEndPoint: window["env"]["userWebSocketEndPoint"] || "default",
  FUNDS_API: window["env"]["fund"] || "default",
  BANK_API: window["env"]["bank"] || "default",
  ORDER_REINITATION_API:window["env"]["reinitation"] || "default",

};
