<div *ngIf="config$ | async as setting" class="config-panel">
  <h2 class="headline mb-4">
    <mat-icon class="mr-3" svgIcon="mat:settings"></mat-icon>
    <span>Configuration</span>
  </h2>

  <div class="section">
    <h5 class="subheading">THEME BASE</h5>

    <div (click)="isDark ? disableDarkMode() : enableDarkMode()"
         *ngrxLet="isDark$; let isDark"
         class="rounded-full mt-2 flex items-center cursor-pointer relative bg-contrast-black text-contrast-white select-none"
         matRipple>
      <div class="m-2 h-6 w-6 rounded-full">
        <mat-icon *ngIf="isDark" svgIcon="mat:check"></mat-icon>
        <mat-icon *ngIf="!isDark" svgIcon="mat:close"></mat-icon>
      </div>
      <p class="ml-1 font-medium text-sm">DARK MODE</p>
    </div>
  </div>

  <div class="section">
    <h5 class="subheading">THEME COLORS</h5>

    <div (click)="selectColor(color.value)" *ngFor="let color of colorVariables | keyvalue"
         [class.selected]="isSelectedColor(color.value)"
         [style.backgroundColor]="color.value.light"
         [style.color]="color.value.default"
         class="vex-color-picker rounded-full mt-2 flex items-center cursor-pointer relative"
         matRipple>
      <div [style.backgroundColor]="color.value.default"
           class="m-2 h-6 w-6 rounded-full">
        <mat-icon *ngIf="isSelectedColor(color.value)" svgIcon="mat:check"></mat-icon>
      </div>
      <p class="ml-1 font-medium text-sm">{{ color.key | uppercase }}</p>
    </div>
  </div>


  <div class="section">
    <h5 class="subheading">Footer</h5>

    <div class="section-content">
      <mat-slide-toggle (change)="footerVisibleChange($event)" [checked]="setting.footer.visible">Visible
      </mat-slide-toggle>

      <h5 class="subheading">Position</h5>

      <mat-radio-group (change)="footerPositionChange($event)"
                       [value]="setting.footer.fixed ? 'fixed' : 'static'"
                       class="flex flex-col gap-3">
        <mat-radio-button value="fixed">Fixed</mat-radio-button>
        <mat-radio-button value="static">Static</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
