import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer, map } from 'rxjs';
import { EmitterService } from 'src/app/services/emitter.service';
import { WebSocketAPI } from 'src/app/services/websocket.service';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {
  //serverTime:any=new Date().getTime();
  //interval:any;
  constructor(private timerService:EmitterService,private ws:WebSocketAPI){
  // this.timerService.timerBrodcast.subscribe(data =>{
  //     this.serverTime=data.data;

  //     if(this.interval){
  //         clearInterval(this.interval);
  //     }

  //     this.interval= setInterval(() => {
  //         this.serverTime= this.serverTime+1000;
  //         //console.log(new Date(this.serverTime))
  //     }, 1000);
  // })
}

  public transform(futureDate: any): Observable<any> {
      if (!futureDate || this.getMsDiff(futureDate) < 0) {
        return null;
      }
  
      const source = timer(0, 1000);
      return source.pipe(map((_) => this.msToTime(this.getMsDiff(futureDate))));
  
      // return timer(0, 1000).pipe(
      //   map(() => {
      //     // never gets hit
      //       const timeRemaining = this.msToTime(this.getMsDiff(futureDate));
      //       console.log(timeRemaining);
      //       return timeRemaining;
      //   })
      // );
    }
  
    private getMsDiff = (futureDate: string): number =>
      +new Date(futureDate)  - (this.ws.getServerTime() ? this.ws.getServerTime() : Date.now());
  
    private msToTime(msRemaining: number): string | null {
      if (msRemaining < 0) {
        console.info('No Time Remaining:', msRemaining);
        return null;
      }
  
      let seconds: string | number = Math.floor((msRemaining / 1000) % 60),
        minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60),
        hours: string | number = Math.floor(
          (msRemaining / (1000 * 60 * 60)) % 24
        );
  
      /**
       * Add the relevant `0` prefix if any of the numbers are less than 10
       * i.e. 5 -> 05
       */
      seconds = seconds < 10 ? '0' + seconds : seconds;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      hours = hours < 10 ? '0' + hours : hours;
  
      return `${hours}:${minutes}:${seconds}`;
    }
  
}
