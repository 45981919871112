import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { WebSocketShareService } from './services/websocket.share.service';
import { WebSocketAPI } from './services/websocket.service';
import { AuthService } from './services/auth.service';
import { EmitterService } from './services/emitter.service';
import { TokenStorageService } from './services/token-storage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  dataVisible:any;
  constructor(private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private websocketService: WebSocketShareService,
    private webSocketAPI: WebSocketAPI, private api: AuthService,private emitter:EmitterService,
    private tokenService:TokenStorageService,
    public dialog: MatDialog
    ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'gif':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.gif`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );

          case 'rupees':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
          case 'list':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     * 
     */


    
    

    //this.getMenuList();
  }
  ngOnInit(): void {
    //this.connect();
    //this.onNewValueReceive();

    // setTimeout(() =>{
    //   this.webSocketAPI.stompClient.send();
    // },5000)

    // this.emitter.menuSourceBrodcast.subscribe(data => {
    //   if(data){
    //     this.getMenuList();
    //   }
    // })
    var size = window.matchMedia("(max-width: 768px)")
    this.checkScreenSize(size) 
  //  size.addListener(this.checkScreenSize) 
 
  try {
    // Chrome & Firefox
    size.addEventListener('change', (e) => {
      if (size.matches) { 
        this.dataVisible=this.dialog.open(ShowDailog, {
             height: '300px',
             width: '300px',
             disableClose: true,
             backdropClass: 'dialog-bg-trans',
             autoFocus: false  
           }); 
         }else{
           this.dialog.closeAll() 
         }
    });
  } catch (e1) {
    try {
      // Safari
      size.addListener((e) => {
        if (size.matches) { 
          this.dataVisible=this.dialog.open(ShowDailog, {
               height: '300px',
               width: '300px',
               disableClose: true ,
               backdropClass: 'dialog-bg-trans',
               autoFocus: false 
             }); 
           }else{
             this.dialog.closeAll() 
           }
      });
    } catch (e2) {
      console.error(e2);
    }
  }
  // this.connect();
  }
  checkScreenSize(size) {
    if (size.matches) { 
      this.dataVisible=this.dialog.open(ShowDailog, {
           height: '300px',
           width: '300px',
           disableClose: true,
           backdropClass: 'dialog-bg-trans',
           autoFocus: false 
         }); 
       }else{
         this.dialog.closeAll() 
       }


  }

  // getMenuList() {

  //   this.navigationService.items=[];

  //   this.navigationService.items = [
  //     {
  //       type: 'subheading',
  //       label: 'Dashboards',
  //       children: [
  //         {
  //           type: 'link',
  //           label: 'Analytics',
  //           route: '/main/dashboards/analytics',
  //           icon: 'mat:insights',
  //           routerLinkActiveOptions: { exact: true }
  //         },
          
  //       ]
  //     },
  //   ]
  //   this.api.get('auth-service/menuMaster/getMenuList').subscribe(
  //     response => {
  //       console.error("Menu Data",response);

        

  //       this.navigationService.items.push(
  //         {
  //           type: 'subheading',
  //           label: 'Features',
  //           children:response
  //         }
  //       )

  //       //this.navigationService.items.push.apply(this.navigationService.items, response)
  //       //this.navigationService.items.push(response);
  //       this.navigationService.items.push(
  //         {
  //           type: 'subheading',
  //           label: 'Quick Help',
  //           children: [
    
  //             {
  //               type: 'link',
  //               label: 'FAQ',
  //               icon: 'mat:help',
  //               route: '/main/pages/faq'
  //             },
  //             {
  //               type: 'link',
  //               label: 'Guides',
  //               icon: 'mat:book',
  //               route: '/main/pages/guides',
  //               badge: {
  //                 value: '18',
  //                 bgClass: 'bg-teal',
  //                 textClass: 'text-teal-contrast',
  //               },
  //             },
  //             {
  //               type: 'link',
  //               label: 'Bid Confirmation',
  //               route: '/main/apps/bidconfirmation',
  //               icon: 'mat:confirmation_number'
  //             },
  //           ]
  //         },
  //         {
  //           type: 'link',
  //           label: 'Configuration',
  //           route: () => this.layoutService.openConfigpanel(),
  //           icon: 'mat:settings'
  //         }
  //       )

  //       this.tokenService.saveMenuList(this.navigationService.items);
        
  //     }
  //   );
  // }

  ngOnDestroy() {
    //this.disconnect();
  }


  

  // method to receive the updated data.
  wsData: any;
  // onNewValueReceive() {
  //   this.websocketService.getNewValue().subscribe(resp => {
  //     this.wsData = resp;
  //   });
  // }

}


@Component({
  selector: 'show-dailog',
  templateUrl: 'show-dialog.html',
})
export class ShowDailog {
  constructor(
    public dialogRef: MatDialogRef<ShowDailog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
