import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const TAC_KEY = 'user-tac';
const TAC_KEY_LIST = 'user-tac-list';
const LANGUAGE_KEY = 'lang';
const MENU_KEY = 'menu';
const CSRF_TOKEN_KEY = 'csrf-token';
const LOGIN_TYPE_KEY = 'logintype';
const FILTER_KEY = 'filter';
const FILTER_TYPE_VALUE="filterTypeValue"
@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {


  public nameSource = new BehaviorSubject<any>('');
  routeBrodcast:Observable<any> = this.nameSource.asObservable()

  constructor(private jwtHelper:JwtHelperService,private cookieService: CookieService ) { }
  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveMenuList(menuList){
    window.sessionStorage.setItem(MENU_KEY, JSON.stringify(menuList));
  }
  public clearMenuList(){
    window.sessionStorage.removeItem(MENU_KEY);
  }
  public getMenuList(){
    const menu = window.sessionStorage.getItem(MENU_KEY);
    if (menu) {
      return JSON.parse(menu);
    }
    return null;
  }
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }
  public removeToken(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
  }

  public saveCsrfToken(token: string): void {
    window.sessionStorage.removeItem(CSRF_TOKEN_KEY);
    window.sessionStorage.setItem(CSRF_TOKEN_KEY, token);
  }
  public removeCsrfToken(): void {
    window.sessionStorage.removeItem(CSRF_TOKEN_KEY);
  }

  public getCsrfToken(): string | null {
    return window.sessionStorage.getItem(CSRF_TOKEN_KEY);
  }


  public removeUser(): void {
    window.sessionStorage.removeItem(USER_KEY);
  }
  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    
    window.sessionStorage.setItem(TAC_KEY_LIST, JSON.stringify (user.tacList));
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public getSelectedTac(){
    return window.sessionStorage.getItem(TAC_KEY);
  }

  public setSelectedTac(tacId){
    return window.sessionStorage.setItem(TAC_KEY,tacId);
  }

  public getTacList(){
    return JSON.parse(window.sessionStorage.getItem(TAC_KEY_LIST));
  }
 

  public isAuthenticated(): boolean {

        return !this.jwtHelper.isTokenExpired(this.getToken());

  }


  public getLanguage(){
    return  window.sessionStorage.getItem(LANGUAGE_KEY);
  }


  public setLanguage(value){
    return  window.sessionStorage.setItem(LANGUAGE_KEY,value);
  }

  public getLoginType(){
    return  window.sessionStorage.getItem(LOGIN_TYPE_KEY);
  }


  public setLoginType(value){
    return  window.sessionStorage.setItem(LOGIN_TYPE_KEY,value);
  }

  public removeTac(): void {
    window.sessionStorage.removeItem(TAC_KEY);
  }

  public setFilter(type,isselected,data){
    
    let dString=this.cookieService.get(FILTER_KEY+"|"+type);
    if(dString){
      let dObject=JSON.parse(dString);
      if(!dObject){
          dObject=[];
      }
      if(isselected){
        dObject.push(data);
        this.cookieService.set(FILTER_KEY+"|"+type,JSON.stringify(dObject),this.getExpiry());
      }else{

        dObject=dObject.filter(e => e !== data)

        //dObject.splice(data);
        this.cookieService.set(FILTER_KEY+"|"+type,JSON.stringify(dObject),this.getExpiry());
      }
      return dObject;
    }else{
      let dObject=[];
      
      if(isselected){
        dObject.push(data);
        this.cookieService.set(FILTER_KEY+"|"+type,JSON.stringify(dObject),this.getExpiry());
      }else{
        dObject.splice(data);
        this.cookieService.set(FILTER_KEY+"|"+type,JSON.stringify(dObject),this.getExpiry());
      }
      return dObject;
    }
    

  }


  public getFilter(type){
    let dString=this.cookieService.get(FILTER_KEY+"|"+type);


    if(dString){
      let dObject=JSON.parse(dString);
      if(!dObject){
          dObject=[];
      }
      return dObject;
    }else{
      return [];
    }
    

  }

  getExpiry() {
    // const now = DateTime.now();
    // // Set the time zone to Asia/Kolkata
    // const midnightInKolkata = now.setZone('Asia/Kolkata').startOf('day').plus({ days: 1 });

    // // Set the time to 11:59 PM
    // const elevenFiftyNinePM = midnightInKolkata.minus({ seconds: 1 });

    // return elevenFiftyNinePM.toJSDate();

    const now = DateTime.now();


    const currentDateTimeInKolkata = now.setZone('Asia/Kolkata');

    const expirationTime = currentDateTimeInKolkata.set({ hour: 23, minute: 59 });

    return expirationTime.toJSDate();

  }
clearCookiesForSearchHistoryFilter(type){

      this.cookieService.set(FILTER_KEY+"|"+type,JSON.stringify([]),0);
    
  }
  clearActiveFilterTypeCookies(){
    this.cookieService.set(FILTER_TYPE_VALUE,JSON.stringify([]),0);
  }
  setFilterTypeCookies(type,isselected,data){
    this.cookieService.set(FILTER_TYPE_VALUE,data,this.getExpiry());
    
  }
 public getFilterTypeCookies(){
    let dString=this.cookieService.get(FILTER_TYPE_VALUE);
    return dString;

  }
}
