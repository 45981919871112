import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, ShowDailog } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { NgOtpInputModule } from 'ng-otp-input';
import { ConfirmDialogService } from './pages/alerts/confirm-dialog.service';
import { ConfirmDialogModule } from './pages/alerts/confirm-dialog.module';
import { NgxLoadingModule } from 'ngx-loading';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { WebSocketShareService } from './services/websocket.share.service';
import { WebSocketAPI } from './services/websocket.service';
import { CustomPaginationComponent } from './pages/apps/custom-pagination/custom-pagination.component';
import { SharedModule } from './shared/shared.module';
import { AlterSuperH1Component } from './pages/apps/alter-super-h1/alter-super-h1.component';

import { CookieService } from 'ngx-cookie-service';




const TOKEN_KEY = 'auth-token';
export function tokenGetter() {
  return window.sessionStorage.getItem(TOKEN_KEY);
}


@NgModule({
  declarations: [AppComponent, CustomPaginationComponent,ShowDailog,],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ConfirmDialogModule,
    MatChipsModule,
    SharedModule,
    NgxSlickJsModule.forRoot(),
    // Vex
    VexModule,
    CustomLayoutModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    NgOtpInputModule,
    NgxLoadingModule.forRoot({}),
    MatRadioModule,
    //CookieService
  ],
  providers: [
    JwtHelperService,
    ConfirmDialogService,
    WebSocketAPI,
    WebSocketShareService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
