import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[noSlashInput]'
})
export class NoSlashInputDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const sanitizedValue = this.sanitizeInputValue(inputValue);
    this.ngControl.control?.setValue(sanitizedValue);
  }

  private sanitizeInputValue(value: string): string {
    const specialChars = /['"\+=^?\/\\*&!`<>]/g;
    return value.replace(specialChars, '');
  }
}