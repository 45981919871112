import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  userSession: any;

  constructor(private readonly popoverRef: PopoverRef, private token: TokenStorageService

    ) { }

  ngOnInit(): void {

    this.userSession = this.token.getUser();
    console.log(this.userSession)

  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }
}
