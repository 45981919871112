import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[maxMarginDirective]'
})
export class maxMarginDirective {


  constructor(private el: ElementRef ,private control: NgControl) { }

  @HostListener('input') onInput() {
    let inputValue: string = this.el.nativeElement.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    const parts = inputValue.split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
    const formattedDecimal = decimalPart.length > 2 ? decimalPart.slice(0, 3) : decimalPart;
    this.control.control.setValue(`${integerPart}${formattedDecimal}`, { emitEvent: false });
  }
}