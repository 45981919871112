import { AuctionFloor2Module } from './pages/apps/auction-floor2/auction-floor2.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './interceptor/auth.guard';



const routes: VexRoutes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages/auth/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'error-401',
    loadChildren: () => import('./pages/pages/errors/error-401/error-401.module').then(m => m.Error404Module),
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'change-password-public',
    loadChildren: () => import('./pages/pages/auth/change-password-public/change-password-public.module').then(m => m.ChangePasswordPublicModule),
  },
  {
    path: 'multistate-participation',
    loadChildren: () => import('./pages/pages/auth/multistate-participation/multistate-participation.module').then(m => m.MultistateParticipationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'participation',
    loadChildren: () => import('./pages/pages/auth/participation/participation.module').then(m => m.ParticipationModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'main',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'apps',
        children: [
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'market-watch',
            loadChildren: () => import('./pages/apps/market-watch/market-watch.module').then(m => m.MarketWatchModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'best-bid-price',
            loadChildren: () => import('./pages/apps/best-bid-price/best-bid-price.module').then(m => m.BestBidPriceModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'auction-floor',
            loadChildren: () => import('./pages/apps/trade-floor/trade-floor.module').then(m => m.TradeFloorModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'registration',
            loadChildren: () => import('./pages/apps/registration/registration.module').then(m => m.RegistrationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'auction-floor2',
            loadChildren: () => import('./pages/apps/auction-floor2/auction-floor2.module').then(m => m.AuctionFloor2Module),
            canActivate: [AuthGuard]
          },
          {
            path: 'order-entry',
            loadChildren: () => import('./pages/apps/order-entry/order-entry.module').then(m => m.OrderEntryModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bid-history',
            loadChildren: () => import('./pages/apps/bid-history/bid-history.module').then(m => m.BidHistoryModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bidconfirmation',
            loadChildren: () => import('./pages/apps/bidconfirmation/bidconfirmation.module').then(m => m.BidconfirmationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'winnerlist',
            loadChildren: () => import('./pages/apps/winner-list/winner-list.module').then(m => m.WinnerListModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'reports',
            loadChildren: () => import('./pages/apps/reports/reports.module').then(m => m.ReportsModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'register',
            loadChildren: () => import('./pages/apps/registration/registration.module').then(m => m.RegistrationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bilateral-market',
            loadChildren: () => import('./pages/apps/bilateral-market/bilateral-market.module').then(m => m.BilateralMarketModule),
            // canActivate:[AuthGuard]
          },
          {
            path: 'market',
            loadChildren: () => import('./pages/apps/market-master/market-master.module').then(m => m.MarketMasterModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'contract',
            loadChildren: () => import('./pages/apps/contract-mstr/contract-mstr.module').then(m => m.ContractMstrModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'copy-contract',
            loadChildren: () => import('./pages/apps/copy-contract/copy-contract.module').then(m => m.CopyContractModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'commodity',
            loadChildren: () => import('./pages/apps/commodity-master/commodity-master.module').then(m => m.CommodityMasterModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'groupCode',
            loadChildren: () => import('./pages/apps/group-code-master/group-code-master.module').then(m => m.GroupCodeMasterModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'mapping',
            loadChildren: () => import('./pages/apps/market-mapping/market-mapping.module').then(m => m.MarketMappingModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'trading-session',
            loadChildren: () => import('./pages/apps/trading-session-mstr/trading-session-mstr.module').then(m => m.TradingSessionMstrModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'localtion-master',
            loadChildren: () => import('./pages/apps/location-master/location-master.module').then(m => m.LocationMasterModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'approval',
            loadChildren: () => import('./pages/apps/approval/approval.module').then(m => m.ApprovalModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'time-session-map',
            loadChildren: () => import('./pages/apps/time-session-map/time-session-map.module').then(m => m.TimeSessionMapModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'token',
            loadChildren: () => import('./pages/apps/token-generation/token-generation.module').then(m => m.TokenGenerationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'create-trading-account',
            loadChildren: () => import('./pages/apps/create-trading-account/create-trading-account.module').then(m => m.CreateTradingAccountModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'auction-upload',
            loadChildren: () => import('./pages/apps/auction-upload/auction-upload.module').then(m => m.AuctionUploadModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'mapping-upload',
            loadChildren: () => import('./pages/apps/mapping-upload/mapping-upload.module').then(m => m.MappingUploadModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'auction-upload',
            loadChildren: () => import('./pages/apps/auction-upload/auction-upload.module').then(m => m.AuctionUploadModule),
            canActivate: [AuthGuard]
          }, {
            path: 'super-h1-upload',
            loadChildren: () => import('./pages/apps/super-h1-upload/super-h1-upload.module').then(m => m.SuperH1UploadModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'orderReinitation',
            loadChildren: () => import('./pages/apps/order-reinitation/order-reinitation.module').then(m => m.OrderReinitationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'reservedPriceModification',
            loadChildren: () => import('./pages/apps/reserved-price-modification/reserved-price-modification.module').then(m => m.ReservedPriceModificationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'bulkUpload',
            loadChildren: () => import('./pages/apps/bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'plantationDetails',
            loadChildren: () => import('./pages/apps/plantation/plantation.module').then(m => m.PlantationModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'view-client',
            loadChildren: () => import('./pages/apps/view-client/view-client.module').then(m => m.ViewClientModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'edit-client',
            loadChildren: () => import('./pages/apps/view-client/view-client.module').then(m => m.ViewClientModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'mpms',
            loadChildren: () => import('./pages/apps/mpms/mpms.module').then(m => m.MpmsModule)
          },
          {
            path: 'alterSuperH1',
            loadChildren: () => import('./pages/apps/alter-super-h1/alter-super-h1.module').then(m => m.AlterSuperH1Module),
            canActivate: [AuthGuard]
          }, {
            path: 'extraDocument',
            loadChildren: () => import('./pages/apps/extra-document-upload/extra-document-upload.module').then(m => m.ExtraDocumentUploadModule),
          },
          {
            path: 'auction-tracker',
            loadChildren: () => import('./pages/apps/auction-tracker/auction-tracker.module').then(m => m.AuctionTrackerModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'user-market-mapping',
            loadChildren: () => import('./pages/apps/user-market-mapping/user-market-mapping.module').then(m => m.UserMarketMappingModule),
            canActivate: [AuthGuard]
          }
          , {
            path: 'changePassword',
            loadChildren: () => import('./pages/apps/registration/change-password/change-password.module').then(m => m.ChangePasswordModule),
          },
          {
            path: 'fund-deposit',
            loadChildren: () => import('./pages/apps/funds/fund-deposit/fund-deposit.module').then(m => m.FundDepositModule),
            canActivate: [AuthGuard]

          },
          {
            path: 'fund-deposit-approval',
            loadChildren: () => import('./pages/apps/funds/fund-deposit-approval/fund-deposit-approval.module').then(m => m.FundDepositApprovalModule),
            canActivate: [AuthGuard]

          },
          {
            path: 'funds-withdrawal',
            loadChildren: () => import('./pages/apps/funds/funds-withdrawal/funds-withdrawal.module').then(m => m.FundsWithdrawalModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'fund-withdrawal-approval',
            loadChildren: () => import('./pages/apps/funds/fund-withdrawal-approval/fund-withdrawal-approval.module').then(m => m.FundWithdrawalApprovalModule),
            canActivate: [AuthGuard]
          },

          {
            path: 'fundsledger',
            loadChildren: () => import('./pages/apps/funds/funds-ledger/funds-ledger.module').then(m => m.FundsLedgerModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'offlineledgerentry',
            loadChildren: () => import('./pages/apps/funds/offline-ledger/offline-ledger.module').then(m => m.OfflineLedgerModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'paymentfiledetailsview',
            loadChildren: () => import('./pages/apps/funds/paymentfile-details-view/paymentfile-details-view.module').then(m => m.PaymentfileDetailsViewModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'paymentfiledetails',
            loadChildren: () => import('./pages/apps/funds/paymentfile-details/paymentfile-details.module').then(m => m.PaymentfileDetailsModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'reprocessbankfile',
            loadChildren: () => import('./pages/apps/funds/re-processbankfile/re-processbankfile.module').then(m => m.ReProcessbankfileModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'marginbook',
            loadChildren: () => import('./pages/apps/funds/marginBook/margin-book.module').then(m => m.MarginBookModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'fund-margin-transfer',
            loadChildren: () => import('./pages/apps/funds/fund-margin-transfer/fund-margin-transfer.module').then(m => m.FundMarginTransferModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'marginblockreleasebook',
            loadChildren: () => import('./pages/apps/funds/margin-block-release-book/margin-block-release-book.module').then(m => m.MarginBlockReleaseBookModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'marginpayoutreport',
            loadChildren: () => import('./pages/apps/funds/margin-payout-report/margin-payout-report.module').then(m => m.MarginPayoutReportModule),
            canActivate: [AuthGuard]
          },

          {
            path: 'fields-update-screen',
            loadChildren: () => import('./pages/apps/fields-update-screen/fields-update-screen.module').then(m => m.FieldsUpdateScreenModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'provisional-trade',
            loadChildren: () => import('./pages/apps/provisional-trade/provisional-trade.module').then(m => m.ProvisionalTradeModule),
            //canActivate: [AuthGuard]
          },
          {
            path: 'upload-collection-file',
            loadChildren: () => import('./pages/apps/funds/upload-collection-file/upload-collection-file.module').then(m=>m.UploadCollectionFileModule),
            canActivate: [AuthGuard]
          },

        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },

      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
