<div class="card overflow-auto flex flex-col md:flex-row sm:mr-6">
  <div class="bg-primary/10 p-gutter flex-auto max-w-[300px]">
    <h2 class="headline mb-4 text-primary">Quick Access</h2>

    <p class="caption">Access Module Directly from Here and Participate in Your Favorite Auction</p>

  

    
  </div>

  <div class="p-gutter flex-auto max-w-[400px]">
    <h3 class="body-2 m-0">Features</h3>

    <div class="mt-4 grid grid-cols-3">
      <a (click)="close()"
         *ngFor="let feature of features"
         [routerLink]="feature.route"
         class="text-dark p-3 text-center hover:bg-primary/10 hover:text-primary trans-ease-out rounded block no-underline">
        <mat-icon [svgIcon]="feature.icon" class="icon-xl" color="primary"></mat-icon>
        <div class="body-2 mt-2">{{ feature.label }}</div>
      </a>
    </div>
  </div>

 
</div>

