import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { FormControl } from '@angular/forms';
import { WebSocketShareService } from 'src/app/services/websocket.share.service';
import { WebSocketAPI } from 'src/app/services/websocket.service';
import { EmitterService } from 'src/app/services/emitter.service';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  megaMenuOpen$: Observable<boolean> = of(false);
  userSession: any;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              private tokenService:TokenStorageService,
              private wsShared:WebSocketShareService,
              public websocketApi:WebSocketAPI,
              private emmiterService: EmitterService) { }

  tradingAccList:any=[];
  tacControl = new FormControl();
  availableMargin:any;
  blockedMargin:any;
  //serverTime:any;
  serverDisplay:any;
  interval:any;
  ngOnInit(): void {
    window.onbeforeunload = () => {
      localStorage.setItem('pageRefreshed', 'true');
    };
    
    const pageRefreshed = localStorage.getItem('pageRefreshed');

    if (pageRefreshed === 'true') {
      console.log('Page was refreshed');
      this.tokenService.removeTac();  
    } else {
      console.log('Page was not refreshed');  
    }
    this.wsShared.broadcastMargin.subscribe(data => {
      if(data){
        this.availableMargin=data.availableMargin;
        this.blockedMargin=data.blockedMargin;
      }
   })
//    this.interval=setInterval(()=>{
   
// let time=this.websocketApi.serverTime;
// console.warn(this.websocketApi.serverTime);
// this.serverTime=time+1000;
// //clearInterval(this.interval);


// },1000);
//    this.emmiterService.timerBrodcast.subscribe(data => {
//     if(data){
//       this.serverTime= data.data;
//       if(this.interval){
//         clearInterval(this.interval);
//     }

//     this.interval= setInterval(() => {
//         this.serverTime= this.serverTime+1000;
       
//         //console.log(new Date(this.serverTime))
//     }, 1000);

     
//     }
//  })

   this.tradingAccList=this.tokenService.getTacList();

   if(this.tradingAccList && this.tradingAccList.length==1){
      this.tacControl.setValue(this.tradingAccList[0]);
      this.broadCastTacSelection(this.tradingAccList[0]);
   }

   this.userSession = this.tokenService.getUser();

  }

  openQuickpanel(): void {
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  onChangeTac(event){
    console.log(event.value);
    this.broadCastTacSelection(event.value);
  }

  onRefreshMargin(){
    this.websocketApi.getMargin();
  }


    broadCastTacSelection(tacId:any){
      this.tokenService.setSelectedTac(tacId);
      this.emmiterService.setSelectedTac(tacId)
      this.websocketApi.getMargin();
    }
    
}
