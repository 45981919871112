import { Component, OnInit,Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import { WebSocketShareService } from 'src/app/services/websocket.share.service';
import { PopoverRef } from 'src/@vex/components/popover/popover-ref';
@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit , AfterViewInit {

  //notifications: string[] = [];

  trackById = trackById;


  @Input() notifications: string[] = [];


  constructor(private popoverRef: PopoverRef) { 

    this.notifications = this.popoverRef.data.notifications;

    
  }

  ngOnInit() {


  }


  @ViewChild('messageDiv') messageDiv: ElementRef;


  ngAfterViewInit(): void {
    //let messageDiv = document.querySelector('#messageDiv');


    let html=`<a class="notification flex items-center" matRipple class="p-4"> <div class="flex-auto">`

    for (let index = 0; index < this.notifications.length; index++) {
      html+=`<a class="notification flex items-center" matRipple> <div class="flex-auto">`
      html+=`<div class="notification-label" style="margin-bottom:5px; font-size: small;">`+this.notifications[index]+ `</div>`
      html+=`</div></a>`
      html+=`<hr>`
    }
    

    this.messageDiv.nativeElement.innerHTML=html;

  }

}
