<div class="overflow-hidden">
    <div class="h-24 w-full flex justify-center">
        <img class="w-32 "
              src="../assets/img/illustrations/undraw_progressive_app_m-9-ms (1).svg" alt="">
    </div>
    <div class="capitalize mt-2 text-center" style="font-family:Verdana ;">
        <span>for better experience download our mobile application also available in app store.</span>
    </div>
    <div class="flex justify-center w-full gap-6 mt-4">
        <div class="w-32 h-16">
            <a target="_blank" href='https://play.google.com/store/apps/details?id=com.neml.nafedauctions&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
              </a>           
             </div>
            <!-- <a target="_blank" href="https://play.google.com/store/apps/details?id=com.neml.nafedauctions" target="_blank"><img class="w-full"
              src="../assets/img/landing/icons/Google-Play_button.png" alt=""></a></div> -->
      </div>
</div>