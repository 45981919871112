import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { HttpClient } from '@angular/common/http';
import { CurrentMarketWatchDataComponent } from 'src/app/pages/apps/market-watch/market-watch-data/current-market-watch-data/current-market-watch-data.component';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { SearchFilterPipe } from 'src/app/shared/custom-pipes/search-filter.pipe';
import { SearchModule } from '../search/search.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmDialogService } from 'src/app/pages/alerts/confirm-dialog.service';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, SharedModule,MatButtonModule, MatRippleModule,ReactiveFormsModule, MatCheckboxModule,FormsModule],
  selector: 'vex-search-modal',
  templateUrl: './search-modal.component.html',
  styles: [`
  `]
})
export class SearchModalComponent implements OnInit {

  constructor(private authService: AuthService, private tokenService: TokenStorageService, private http: HttpClient,private dialogService:ConfirmDialogService) { }
  filterGlobalClientList: any = [];
  @ViewChild(CurrentMarketWatchDataComponent) currentMarketWatchData:CurrentMarketWatchDataComponent;
  filterCommodityMasterModelList: any = [];
  favouriteList: any = {};
  commodityListData: any[] = [];
  globalClientList: any[] = []
  searchData:any;
  searchCtrl= new UntypedFormControl();
  ngOnInit(): void {
    this.getActiveFilters();
  }
  getActiveFilters() {
    this.authService.auctionMarketGet('auction-service/master/getActiveFiltersByFilterType').subscribe(res => {

      this.filterGlobalClientList = res.ActiveFiltersData.globalClientList;
      this.filterCommodityMasterModelList = res.ActiveFiltersData.commodityMasterModelList;
    })
  }
 
  addFavourite(paramterName: any, paramterValue: any, event: any) {
    if(event.checked == true){
    if (paramterName === 'commodity') {
      if (event.checked) {
        this.commodityListData.push(paramterValue);
        this.favouriteList.commodityCodeList = this.commodityListData;
      } else {
        this.commodityListData = this.commodityListData.filter(element => element !== paramterValue);
        this.favouriteList.commodityCodeList = this.commodityListData;
      }

    } if (paramterName === 'globalClient') {
      if (event.checked) {
        this.globalClientList.push(paramterValue)
        this.favouriteList.globalClientList = this.globalClientList;
      } else {
        this.globalClientList = this.globalClientList.filter(element => element !== paramterValue);
        this.favouriteList.globalClientList = this.globalClientList;
      }
    }
    this.favouriteList.tradingAccNo = this.tokenService.getSelectedTac();
    if (this.favouriteList.tradingAccNo == undefined || this.favouriteList.tradingAccNo == null) {
      this.dialogService.showError("Please Select Trading Account No");
      return;
    }
    this.authService.auctionMarketPost('auction-service/marketWatchEM/addAllBookMarks',this.favouriteList).subscribe(
      (data: any) => {
        this.tokenService.nameSource.next('SM');
      }
    )
    }
    // this.favouriteList.isSelected = data.checked;
    // this.favouriteList.internalOrdNo = data.source.value;
    // this.favouriteList.tradingAccNo = this.tokenService.getSelectedTac();
    // this.authService.auctionMarketPost('auction-service/marketWatchEM/addRemoveBookmark', this.favouriteList).subscribe(res => {
    //  console.warn(res)
    // })

  }
}

