<div class="vex-user-menu">
  <a (click)="close()"
     *ngIf="userSession.orgCategory == 'ORG'"
     [routerLink]="['/main/apps/register/new-registration']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="vex-user-menu-item__label" >Your Profile</div>
  </a>
  <!-- <a (click)="close()"
  *ngIf="userSession.orgCategory == 'HST'"
  [routerLink]="['/main/apps/register/admin']"
  class="vex-user-menu-item"
  matRipple
  matRippleColor="rgb(var(--color-primary), 0.1)">
 <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
 <div class="vex-user-menu-item__label" >Admin Registration</div>
</a> -->

  <a (click)="close()"
     [routerLink]="['/main/dashboards/analytics']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:insights"></mat-icon>
    <div class="vex-user-menu-item__label">Analytics</div>
    <div class="vex-user-menu-item__badge">NEW</div>
  </a>

  <a (click)="close()"
     [routerLink]="['/apps/social/timeline']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="vex-user-menu-item__label">Segment Settings</div>
  </a>

  <div class="border-b border-divider mx-4"></div>


  <a (click)="close()"
     [routerLink]="['/register']"
     class="vex-user-menu-item"
     matRipple
     matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="vex-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="vex-user-menu-item__label">Sign Out</div>
  </a>
</div>
