import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SseService } from './sse.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true,
};
const httpOption = {
  withCredentials: true,
};

const options = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  responseType: 'arraybuffer' as 'arraybuffer',
  withCredentials: true,
};
const optionsUploadAndDownload = {
  responseType: 'arraybuffer' as 'arraybuffer',
  withCredentials: true,
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  getData() {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient, private _ngZone: NgZone,
    private _sseService: SseService, private tokenStorage: TokenStorageService) { }


  post(url: any, data: any): Observable<any> {
    return this.http.post(environment.AUCTION_API + url, data, httpOptions);
  }
  get(url: any): Observable<any> {
    return this.http.get(environment.AUCTION_API + url, httpOptions);
  }
  put(url: any, data: any): Observable<any> {
    return this.http.put(environment.AUCTION_API + url, data, httpOptions);
  }
  regPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.REG_API + url, data, httpOptions);
  }
  registrationPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.REG_API + url, data, httpOption);
  }
  regGet(url: any): Observable<any> {
    return this.http.get(environment.REG_API + url, httpOptions);
  }
  regPut(url: any, data: any): Observable<any> {
    return this.http.put(environment.REG_API + url, data, httpOptions);
  }

  masterPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.MASTER_API + url, data, httpOptions);
  }
  masterGet(url: any): Observable<any> {
    return this.http.get(environment.MASTER_API + url, httpOptions);
  }

  saveUserData(formData: FormData): Observable<any> {
    return this.http.post(environment.REG_API + 'registration-service/registration/saveRegistrationData', formData, httpOptions);
  }

  auctionMarketPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.AUCTION_MARKET_API + url, data, httpOptions);
  }
  auctionMarketUploadPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.AUCTION_MARKET_API + url, data, httpOption);
  }
  auctionMarketPut(url: any, data: any): Observable<any> {
    return this.http.put(environment.AUCTION_MARKET_API + url, data, httpOptions);
  }
  auctionMarketGet(url: any): Observable<any> {
    return this.http.get(environment.AUCTION_MARKET_API + url, httpOptions);
  }
  bilateralMarketPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.BILATERAL_API + url, data, httpOption);
  }
  bilateralMarketPut(url: any, data: any): Observable<any> {
    return this.http.put(environment.BILATERAL_API + url, data, httpOptions);
  }
  bilateralMarketGet(url: any): Observable<any> {
    return this.http.get(environment.BILATERAL_API + url, httpOptions);
  }
  verificationApiPost(url: any, data: any): Observable<any> {
    return this.http.post(environment.VERIFICATION_API + url, data,httpOptions);
  }

  masterPostMultipart(url: any, data: any): Observable<any> {
    return this.http.post(environment.MASTER_API + url, data, httpOption);
  }

  masterPostExportExcel(url: any, data: any): Observable<ArrayBuffer> {
    return this.http.post(environment.MASTER_API + url, data, optionsUploadAndDownload);
  }
  downloadTemplateFile(url: any): Observable<ArrayBuffer> {
    return this.http.get(environment.MASTER_API + url, optionsUploadAndDownload);
  }
  downloadFileReg(url: any, data: any): Observable<any> {
    return this.http.post(environment.REG_API + url, data, optionsUploadAndDownload);
  }

  downloadFileGet(url: any, data: any): Observable<any> {
    return this.http.get(environment.REG_API + url, optionsUploadAndDownload);
  }
  uploadAuctionFile(url: any, data: any): Observable<any> {
    return this.http.post(environment.UPLOAD_API + url, data, optionsUploadAndDownload);
  }
  uploadPostData(url: any, data: any): Observable<any> {
    return this.http.post(environment.UPLOAD_API + url, data, optionsUploadAndDownload);
  }
  uploadPostData1(url: any, data: any): Observable<any> {
    return this.http.post(environment.UPLOAD_API + url, data, httpOption);
  }
  uploadMappingFile(url: any, data: any): Observable<any> {
    return this.http.post(environment.UPLOAD_API + url, data, httpOption);
  }
  uploadGetData(url): Observable<any> {
    return this.http.get(environment.UPLOAD_API + url, httpOption);
  }

  contractPostMultipart(url: any, data: any): Observable<any> {
    return this.http.post(environment.MASTER_API + url, data, httpOption);
  }
  contractPostExportExcel(url: any, data: any): Observable<ArrayBuffer> {
    return this.http.post(environment.MASTER_API + url, data, optionsUploadAndDownload);
  }
  downloadExcelPostAuctionMarket(url: any, data: any): Observable<any> {
    return this.http.post(environment.AUCTION_MARKET_API + url, data, optionsUploadAndDownload);
  }
  postFunds(url: any, data: any): Observable<any> {
    return this.http.post(environment.FUNDS_API + url, data, httpOptions);
  }
  postBankSrv(url: any, data: any): Observable<any> {
    return this.http.post(environment.BANK_API + url, data, httpOptions);
  }
  downloadFileMstr(url: any, data: any): Observable<any> {
    return this.http.post(environment.MASTER_API + url, data, optionsUploadAndDownload);
  }
  
  downloadFundsFile(url: any, data: any): Observable<any> {
    return this.http.post(environment.FUNDS_API + url, data, optionsUploadAndDownload);
  }
  orderReinitationServicePost(url: any, data: any): Observable<any> {
    return this.http.post(environment.ORDER_REINITATION_API + url, data, httpOptions);
  }
  orderReinitationServicePut(url: any, data: any): Observable<any> {
    return this.http.put(environment.ORDER_REINITATION_API + url, data, httpOptions);
  }
}
