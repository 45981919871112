import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })
export class EmitterService {

    public nameSource = new BehaviorSubject<any>('');
    routeBrodcast:Observable<any> = this.nameSource.asObservable()
    constructor() { }

    setData(data) {
          this.nameSource.next(data);
    }
    reloadActiveFilter(data,keyValue){
      let dataValue={
        data:data,
        keyValue:keyValue

    }
      this.nameSource.next(dataValue);
    }
    setMylistFilterData(data,keyValue) {
      let dataValue={
            data:data,
            keyValue:keyValue

      }
      this.nameSource.next(dataValue);
}


    public nameTimerSource = new BehaviorSubject<any>('');
    timerBrodcast:Observable<any> = this.nameTimerSource.asObservable()
    

//    public  serverTime:any=new Date().getTime();
//     interval:any;
   // setServerTime(data) {
         // this.nameTimerSource.next(data);


      // this.serverTime=data.data;

      //   if(this.interval){
      //       clearInterval(this.interval);
      //   }

      //   this.interval= setInterval(() => {
      //       this.serverTime= this.serverTime+1000;
      //       //console.log(new Date(this.serverTime))
      //   }, 1000);
    //}

    
    setServerTime(data:any){
      this.nameTimerSource.next(data);
    }

    public auctionFloorSource = new BehaviorSubject<any>('');
    auctionFloorBrodcast:Observable<any> = this.auctionFloorSource.asObservable()
    

    setauctionFloorData(data) {
          this.auctionFloorSource.next(data);
    }

    public marketWatchSource = new BehaviorSubject<any>('');
    marketWatchBrodcast:Observable<any> = this.marketWatchSource.asObservable()
    

    setmarketWatchData(data) {
          this.marketWatchSource.next(data);
    }

    public selectedTacSource = new BehaviorSubject<any>('');
    selectedTacBrodcast:Observable<any> = this.selectedTacSource.asObservable()

    setSelectedTac(data){
      this.selectedTacSource.next(data);
    }

    public disableSubmitSource = new BehaviorSubject<any>('');
    disableSubmitBrodcast:Observable<any> = this.disableSubmitSource.asObservable()

    setDisableSubmit(data){
      this.disableSubmitSource.next(data);
    }
}