import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { WebSocketAPI } from 'src/app/services/websocket.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/main/apps/register/new-registration'
    },
    {
      id: '2',
      icon: 'mat:key',
      label: 'Change Password',
      description: 'Kindly Change Your Password',
      colorClass: 'text-red',
      route: '/main/apps/changePassword'
    },
    // {
    //   id: '3',
    //   icon: 'mat:move_to_inbox',
    //   label: 'My Inbox',
    //   description: 'Your Messages',
    //   colorClass: 'text-primary',
    //   route: '/apps/chat'
    // },
    // {
    //   id: '4',
    //   icon: 'mat:list_alt',
    //   label: 'My Segments',
    //   description: 'Your Favorite Segments',
    //   colorClass: 'text-amber',
    //   route: '/apps/scrumboard'
    // },
    // {
    //   id: '5',
    //   icon: 'mat:table_chart',
    //   label: 'Segment Application',
    //   description: 'Apply in Other Segment',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green'
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private tokenStorage:TokenStorageService,private router: Router,
              private apiService:AuthService,
              public websocketApi:WebSocketAPI) { }

  userSession:any;
  ngOnInit() {
    this.userSession=this.tokenStorage.getUser();

  if(this.userSession.orgCategory == "HST"){
    this.items=this.items.filter(obj => {
      if(obj.id != '1' ){
        return obj;
      }
    })
  }
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout(){
    this.apiService.get('auth-service/login/signout').subscribe(data => {
      console.log(data)
    });
    this.popoverRef.close();
    this.tokenStorage.removeCsrfToken();
    document.cookie = 'XSRF-TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    //const clearHistoryState = { clearHistory: true };
    sessionStorage.clear();
    localStorage.clear();
    
    this.router.navigate(['/register'],{ replaceUrl: true }).then(() => {
      window.location.reload()
      this.websocketApi._disconnect()
    });
      // sessionStorage.clear();
      // localStorage.clear();
      //history.replaceState(null, '');


  }
}
