import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import Swal from 'sweetalert2';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  

  showError(message:any){
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    })
  }

  showSuccess(message:any){
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1000
    })
  }

  fundShowSuccess(message:any){
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 2500
    })
  }

  showFixedSuccess(message:any){
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: message,
    })
  }

}
